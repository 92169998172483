<template>
  <v-card elevation="2" outlined shaped color="accent" class="mt-5 mb-5">
    <!-- Title of article -->
    <v-card-title class="text-justify" style="word-break: break-word">
      <a
        v-bind:href="'https://pubmed.ncbi.nlm.nih.gov/' + pmid"
        target="_blank"
      >
        <h4>
          {{ title }}
          <!-- <v-icon small align>mdi-open-in-new</v-icon> -->
        </h4>
      </a>
    </v-card-title>

    <!-- Date | Journal, author et al  -->
    <v-card-subtitle>
      <p class="ma-0 p-black">
        {{ data | formatDate }} | {{ journal }}
        <!-- | score: {{ score | formatScore }}% -->
      </p>
      <p class="ma-0">
        {{ authors | formatAuthors }}
      </p>
    </v-card-subtitle>

    <!-- Abstract -->
    <Abstract :abstract="abstract" class="pl-4 pr-4 pb-4 rounded-br"></Abstract>
  </v-card>
</template>

<script>
import Abstract from "../components/Abstract.vue";
export default {
  props: ["title", "data", "journal", "authors", "abstract", "pmid", "score"],
  components: {
    Abstract,
  },
};
</script>
