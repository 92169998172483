<template>
  <div v-if="abstract">
    <v-expansion-panels accordion focusable style="color: primary">
      <v-expansion-panel>
        <v-expansion-panel-header ripple color="primary">
          <p class="ma-0 text-secondary">
            <b>Show Abstract</b>
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="accent">
          <p class="mt-5 mb-1 text-justify">
            {{ htmlEntity2utf8(abstract) }}
          </p>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
  <div v-else>
    <p><i> No abstract available </i></p>
  </div>
</template>

<script>
export default {
  props: ["abstract"],
  methods: {
    htmlEntity2utf8(s) {
      const t = document.createElement("span");
      t.innerHTML = s;
      return t.textContent;
    },
  },
};
</script>
